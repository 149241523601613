import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import Section50Background from "components/heroes/Section50Background"
import TitleBox from "components/titles/Titlebox"

import TeamServiceBlock from "../components/blocks/TeamService"
import ContactCTABlock from "../components/blocks/ContactCTA"



const SolarCanopiesPage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/solar-canopy.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
            )
          }
        }
        quoteImage: file(relativePath: { eq: "backgrounds/solar-canopy-single.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
      }
    `
    )

    return (
        <Layout>
            <Seo title="Solar Canopies" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
                    <TitleBox
                        title="Transform your car parks with solar canopies"
                        caption="Products"
                    >
                        <p className="text-2xl text-white font-normal">If you own or operate car parks, then solar canopies will allow you to enjoy on-site clean energy, reducing your carbon footprint and your electricity bills while at the same time providing a valuable service to customers, employees and visitors.</p>
                    </TitleBox>
                </div>
            </ImgBackground>


            {/* Content Area */}
            <section className="page--content py-16 lg:py-32 lg:pb-8">
                <div className="container">

                    <div className="w-full mb-24 text-center">
                        <h2 className="text-5xl max-w-3/4 mx-auto">The benefits of working with Pacific Green for solar canopy installation</h2>
                    </div>

                    <div className="lg:flex lg:space-x-12">
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-4xl text-primary mb-4">Fast and flexible installation</h2>
                            <p>Our canopies come with a 10-metre span between posts, covering four standard car parking spaces per unit and making installation quick and easy. We can install posts directly on concrete or using piles or screw fixings, whichever is easiest for your project.</p>
                        </div>
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-4xl text-primary mb-4">Integrated security</h2>
                            <p>Under-canopy LED lighting minimises light pollution from the solar canopy while giving users plenty of night-time light to move around comfortably and safely. And you can add camera systems to increase safety further.</p>
                        </div>
                    </div>


                </div>
            </section>

            {/* 50/50 image pullquote divider */}
            <section className="bg-primary-50">
                <div className="container lg:flex">
                    <div className="lg:w-1/2">
                        <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 max-w-4xl justify-items-end">
                            <div className="mb-12 lg:w-3/4">
                                <h2 className="text-4xl text-primary mb-4">Sleek, stylish design</h2>
                                <p>Our rafters and purlins are standardised, and all drainpipes are contained within posts to improve the aesthetics of your project. We include all facias, flashings and cover plates as standard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2">
                        <div className="lg:float-left lg:w-1/2-screen h-80 lg:h-full">
                            <Section50Background image={data.quoteImage}>
                                <div className="bg-white bg-opacity-0 w-full h-full block">

                                </div>
                            </Section50Background></div>
                    </div>
                </div>
            </section>

            {/* Content Area */}
            <section className="page--content py-16 lg:py-32 lg:pb-16">
                <div className="container">

                    <div className="lg:flex lg:space-x-12">
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-4xl text-primary mb-4">Easy maintenance</h2>
                            <p>A strong grate over the gutter down the centreline of the canopy stops objects from blocking the waterway while at the same time providing a walkway for easy access to the top of the structure.</p>
                        </div>
                        <div className="mb-12 lg:w-1/2">
                            <h2 className="text-4xl text-primary mb-4">Extra power generation</h2>
                            <p>Our solar canopy structures are designed for the latest extra-large bifacial PV panels, which can generate power on both sides and avoid the formation of hotspots that can reduce panel life.</p>
                        </div>
                    </div>


                </div>
            </section>

            {/* team service block */}
            <section className="border-t">
                <div className="container lg:flex lg:space-x-32 py-16 lg:py-32 ">
                <TeamServiceBlock />
                </div>
            </section>

            {/* CTA contact block */}
            <section className="py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default SolarCanopiesPage
